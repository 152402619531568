var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('RouterNav',{attrs:{"name":_vm.routerName}}),_c('div',{staticClass:"title"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"unit-name"},[_vm._v(_vm._s(_vm.name))])]),_c('el-button',{class:['btn', _vm.allSelected ? 'del-btn' : ''],on:{"click":_vm.allTrigger}},[_vm._v(_vm._s(_vm.allSelected ? '移除全部' : '添加全部')+" ")])],1),_c('div',{staticClass:"wrapper"},_vm._l((_vm.list),function(item,index){return _c('div',{key:item.id,staticClass:"title-list"},[_c('div',{staticClass:"writeLib"},[_c('div',{staticClass:"writeLibTypeName"},[_vm._v(_vm._s(item.writtenList[0].writeLibTypeName))])]),_vm._l((item.writtenList),function(written,wIndex){return _c('div',{key:written.id,staticClass:"written-list"},[_c(written.type === '1' ? 'MultipleChoice'
        : written.type === '2' ? 'ClozeTest'
        : written.type === '3' ? 'ReadingComprehension'
        : written.type === '4' ? 'LexicalManipulation'
        : written.type === '6' ? 'BlankFilling'
        : written.type === '7' ? 'ReadingSort'
        : written.type === '8' ? 'MultipleFilling' : '',{tag:"component",staticClass:"written",attrs:{"item":written,"showTranslate":written.showTranslate}}),_c('div',{staticClass:"shop"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"tag"},[_vm._v(_vm._s(written.writeLibTypeName))]),(written.difficultyName)?_c('div',{staticClass:"tag"},[_vm._v(" 难度："),_c('span',{staticClass:"col-FF3C30"},[_vm._v(_vm._s(written.difficultyName))])]):_vm._e(),_c('div',{staticClass:"tag"},[_vm._v(" 我已布置："),_c('span',{staticClass:"col-329cf3"},[_vm._v(_vm._s(written.frequency)+"次")])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"show-btn",on:{"click":function($event){return _vm.translateTrigger(index, wIndex)}}},[_c('div',[_vm._v(_vm._s(written.showTranslate ? '收起解析' : '展开解析'))]),_c('div',{class:['icon', written.showTranslate ? 'rotate-180' : '']})]),_c('xybutton',{staticClass:"ml-28",attrs:{"type":written.selected ? 'del' : 'add'},on:{"click":function($event){return _vm.writtenTrigger(index, wIndex)}}},[_vm._v(_vm._s(written.selected ? '移除' : '添加')+" ")])],1)])],1)})],2)}),0),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"flex row"},[_c('div',[_c('span',[_vm._v("已选：")]),_c('span',{staticClass:"col-329cf3"},[_vm._v(_vm._s(_vm.preliminNum + _vm.selectedNum)+"题")]),_c('span',{staticClass:"ml-28"},[_vm._v("预计耗时：")]),_c('span',{staticClass:"col-329cf3"},[_vm._v(_vm._s(_vm.time)+"分钟")])]),_c('xybutton',{staticClass:"button button-2",attrs:{"disabled":_vm.preliminNum + _vm.selectedNum === 0},on:{"click":_vm.writtenReview}},[_vm._v("下一步 ")])],1)]),(!_vm.writtenTipsHidden)?_c('TipsDialog',{ref:"TipsDialog",attrs:{"writtenTipsHidden":_vm.writtenTipsHidden}}):_vm._e(),_c('Trolley')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <article v-loading="loading">
    <RouterNav :name="routerName"></RouterNav>
    <div class="title">
      <div class="left">
        <div class="line"></div>
        <div class="unit-name">{{name}}</div>
      </div>
      <el-button
          :class="['btn', allSelected ? 'del-btn' : '']"
          @click="allTrigger"
      >{{allSelected ? '移除全部' : '添加全部'}}
      </el-button>
    </div>
    <div class="wrapper">
      <div class="title-list" v-for="(item, index) in list" :key="item.id">
        <div class="writeLib">
          <div class="writeLibTypeName">{{item.writtenList[0].writeLibTypeName}}</div>
        </div>
        <div class="written-list" v-for="(written, wIndex) in item.writtenList" :key="written.id">
          <component
              :is="written.type === '1' ? 'MultipleChoice'
          : written.type === '2' ? 'ClozeTest'
          : written.type === '3' ? 'ReadingComprehension'
          : written.type === '4' ? 'LexicalManipulation'
          : written.type === '6' ? 'BlankFilling'
          : written.type === '7' ? 'ReadingSort'
          : written.type === '8' ? 'MultipleFilling' : ''"
              :item="written"
              :showTranslate="written.showTranslate"
              class="written"
          >
          </component>
          <div class="shop">
            <div class="left">
              <div class="tag">{{written.writeLibTypeName}}</div>
              <div class="tag" v-if="written.difficultyName">
                难度：<span class="col-FF3C30">{{written.difficultyName}}</span>
              </div>
              <div class="tag">
                我已布置：<span class="col-329cf3">{{written.frequency}}次</span>
              </div>
            </div>
            <div class="right">
              <div class="show-btn" @click="translateTrigger(index, wIndex)">
                <div>{{written.showTranslate ? '收起解析' : '展开解析'}}</div>
                <div :class="['icon', written.showTranslate ? 'rotate-180' : '']"></div>
              </div>
              <xybutton
                  class="ml-28"
                  :type=" written.selected ? 'del' : 'add'"
                  @click="writtenTrigger(index, wIndex)"
              >{{written.selected ? '移除' : '添加'}}
              </xybutton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="flex row">
        <div>
          <span>已选：</span><span class="col-329cf3">{{preliminNum + selectedNum}}题</span>
          <span class="ml-28">预计耗时：</span><span class="col-329cf3">{{time}}分钟</span>
        </div>
        <xybutton
            class="button button-2"
            :disabled="preliminNum + selectedNum === 0"
            @click="writtenReview"
        >下一步
        </xybutton>
      </div>
    </div>

    <TipsDialog
        ref="TipsDialog"
        :writtenTipsHidden="writtenTipsHidden"
        v-if="!writtenTipsHidden"
    ></TipsDialog>

    <Trolley
    ></Trolley>
  </article>
</template>

<script>
  import RouterNav from '@/components/RouterNav.vue'
  import MultipleChoice from '../components/written/MultipleChoice'
  import ClozeTest from '../components/written/ClozeTest'
  import ReadingComprehension from '../components/written/ReadingComprehension'
  import LexicalManipulation from '../components/written/LexicalManipulation'
  import BlankFilling from '../components/written/BlankFilling'
  import ReadingSort from '../components/written/ReadingSort'
  import MultipleFilling from '../components/written/MultipleFilling'
  import Trolley from './components/trolley.vue'
  import TipsDialog from './components/TipsDialog'

  export default {
    name: 'writtenSelect',
    components: {
      RouterNav,
      MultipleChoice,
      ClozeTest,
      ReadingComprehension,
      LexicalManipulation,
      BlankFilling,
      ReadingSort,
      MultipleFilling,
      TipsDialog,
      Trolley
    },
    data() {
      return {
        loading: false,
        id: '',
        name: '',
        routerName: '',
        list: [],
        writtenObj: {},
        time: 0, // 未加入清单的笔试题耗时
        preliminNum: 0, // 未加入清单的笔试题数量
        selectedNum: 0,
        writtenNum: 0,
        assignType: 0, // 1：整套布置,
        writtenTipsHidden: false
      }
    },
    computed: {
      allSelected() {
        return this.selectedNum && this.selectedNum === this.writtenNum
      }
    },
    mounted() {
      this.writtenTipsHidden = localStorage.getItem('writtenTipsHidden')
      this.id = this.$route.query.id
      this.name = this.$route.query.writtenName
      this.routerName = this.$route.query.name
      this.indexModuleName = this.$route.query.indexModuleName
      this.moduleName = this.$route.query.moduleName
      this.moduleId = this.$route.query.moduleId
      const {preliminWritten} = this.common.globalData
      const {writtenObj} = preliminWritten
      if (writtenObj) {
        this.writtenObj = writtenObj
        let selectedNum = 0
        let preliminNum = 0
        Object.keys(writtenObj).forEach((writeLibType) => {
          const item = writtenObj[writeLibType]
          Object.keys(item).forEach((id) => {
            if (id !== 'seq') {
              if (item[id].pId === this.id) {
                selectedNum += 1
              } else {
                preliminNum += 1
              }
            }
          })
        })
        this.selectedNum = selectedNum
        this.preliminNum = preliminNum
        this.time = preliminWritten.time
      }
      this.getWritten()
    },
    beforeDestroy() {
      this.common.globalData.preliminWritten.writtenObj = this.writtenObj
      this.common.globalData.preliminWritten.time = this.time
    },
    methods: {
      assignTypeChange(type) {
        if (this.assignType === type) {
          this.assignType = 0
          return
        }
        this.assignType = type
      },
      writtenReview() {
        this.$router.push({
          name: 'writtenReview',
          query: {
            name: '试题预览',
            indexModuleName: this.indexModuleName,
            moduleName: this.moduleName,
            moduleId: this.moduleId
          }
        })
      },
      allTrigger() {
        const {allSelected} = this
        const {writtenObj} = this
        const {list} = this
        if (allSelected) {
          this.selectedNum = 0
          Object.keys(writtenObj).forEach((writeLibType) => {
            const item = writtenObj[writeLibType]
            Object.keys(item).forEach((id) => {
              if (item[id].pId === this.id) {
                this.time -= item[id].time
                delete item[id]
                if (Object.keys(item).length === 0) delete writtenObj[writeLibType]
              }
            })
          })
        } else {
          this.selectedNum = this.writtenNum
        }
        for (let i = 0, len = list.length; i < len; i += 1) {
          const {writtenList} = list[i]
          for (let j = 0, len = writtenList.length; j < len; j += 1) {
            writtenList[j].selected = !allSelected
            if (!allSelected) {
              const {writeLibType} = writtenList[j]
              const {id} = writtenList[j]
              if (!writtenObj[writeLibType]) writtenObj[writeLibType] = {}
              if (!writtenObj[writeLibType][id]) {
                writtenObj[writeLibType][id] = writtenList[j]
                const {time} = writtenList[j]
                this.time += time
              }
            }
          }
        }
      },
      writtenTrigger(index, wIndex) {
        const {list} = this
        const {writtenList} = list[index]
        const {selected} = writtenList[wIndex]
        const {id} = writtenList[wIndex]
        const {writeLibType} = writtenList[wIndex]
        const {writtenObj} = this
        const {time} = writtenList[wIndex]
        if (selected) {
          delete writtenObj[writeLibType][id]
          if (Object.keys(writtenObj[writeLibType]).length === 0) {
            delete writtenObj[writeLibType]
          }
          this.time -= time
          this.selectedNum -= 1
        } else {
          if (writtenObj[writeLibType]) {
            writtenObj[writeLibType][id] = writtenList[wIndex]
          } else {
            writtenObj[writeLibType] = {}
            writtenObj[writeLibType][id] = writtenList[wIndex]
          }
          this.time += time
          this.selectedNum += 1
        }
        writtenList[wIndex].selected = !selected
        this.$set(this.list, index, list[index])
      },
      translateTrigger(index, wIndex) {
        const {list} = this
        const {writtenList} = list[index]
        writtenList[wIndex].showTranslate = !writtenList[wIndex].showTranslate
        this.$set(this.list, index, list[index])
      },
      getWritten() {
        this.loading = true
        this.$axios({
          method: 'post',
          url: this.$urls.paperQuestions,
          data: {
            id: this.id
          }
        }).then((response) => {
          this.loading = false
          if (response.state) {
            const {data} = response
            const {writtenObj} = this
            let writtenNum = 0
            const arr = []
            const obj = {}
            for (let i = 0, len = data.length; i < len; i += 1) {
              const {writtenList} = data[i]
              writtenNum += writtenList.length
              for (let j = 0, len = writtenList.length; j < len; j += 1) {
                const {writeLibType} = writtenList[j]
                writtenList[j].time = this.common.writtenTime(writtenList[j].type, writtenList[j].libList)
                writtenList[j].pId = this.id
                const {id} = writtenList[j]
                if (writtenObj[writeLibType] && writtenObj[writeLibType][id]) {
                  writtenList[j].selected = true
                }
                if (typeof obj[writeLibType] === 'number') {
                  arr[obj[writeLibType]].writtenList.push(writtenList[j])
                } else {
                  obj[writeLibType] = arr.length
                  const writtenObj = {
                    type: writeLibType,
                    writtenList: [writtenList[j]]
                  }
                  arr.push(writtenObj)
                }
              }
            }
            this.writtenNum = writtenNum
            this.list = arr
          }
        }).catch(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .title {
    @include flex(space-between);
    background-color: #fff;
    height: 66px;
    padding-right: 28px;
    margin-bottom: 10px;

    .left {
      @include flex;
      @include font(17px, #333);

      .line {
        background-color: #309AF2;
        width: 4px;
        height: 14px;
        margin-right: 24px;
      }
    }

    .btn {
      width: 92px;
      height: 30px;
      border-color: #309AF2;
      border-radius: 15px;
      @include font(13px, #309AF2, center);
    }

    .del-btn {
      color: #FF3C30;
      border-color: #FF3C30;
    }
  }

  .wrapper {
    background-color: #fff;
    height: calc(100vh - 284px);
    overflow: auto;
    padding: 0 28px 20px;
    font-size: 17px;
    color: #333;
    position: relative;
    z-index: 90;

    .writeLib {
      .writeLibTypeName {
        height: 54px;
        line-height: 54px;
        padding-left: 14px;
        font-weight: bold;
      }
    }

    .written-list {
      border: 1px solid #E5E5E5;
      border-radius: 8px;
      margin-bottom: 10px;
      overflow: hidden;
    }

    .written {
      padding: 20px 20px 0;
    }

    .shop {
      @include flex(space-between);
      background-color: #FBFBFB;
      height: 44px;
      padding: 0 28px 0 20px;
      border-top: 1px solid #F1F1F1;

      .left,
      .right {
        display: flex;
        align-items: center;
      }

      .tag {
        background-color: #F6F6F6;
        height: 28px;
        line-height: 28px;
        padding: 0 10px;
        border: 1px solid #E5E5E5;
        border-radius: 4px;
        margin-right: 10px;
        font-size: 12px;
        text-align: center;
      }

      .show-btn {
        @include flex;
        color: #309AF2;
        font-size: 14px;
        cursor: pointer;

        .icon {
          background-image: url("../../../assets/z-1.png");
          background-size: 100% 100%;
          width: 7px;
          height: 4px;
          margin-left: 4px;
        }
      }
    }
  }

  .footer {
    background-color: #fff;
    height: 84px;
    box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, .06);
    position: relative;
    z-index: 99;

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 84px;
    }

    .row {
      padding: 0 28px;
    }

    .col {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button {
      background-color: #EAF4FD;
      width: 250px;
      height: 40px;
      border-color: #309AF2;
      font-size: 17px;
      color: #309AF2;
    }

    .button-2 {
      background: linear-gradient(to right, #6DBBFC, #309AF2);
      border-color: transparent;
      color: #fff;
    }

    .button-3 {
      background-color: #FF3C30;
      color: #fff;
      border-color: transparent;
    }
  }

  .fs-12 {
    font-size: 12px;
  }
</style>

<template>
  <el-dialog
    title="提示"
    width="450px"
    :visible="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleClose"
  >
    <p class="mb-20">1.选题布置时，您可从套卷中选择任意题目进行组合布置。其中，允许跨卷选择笔试题目。</p>
    <p>2.勾选了题目后，您可点击右下方的“试题预览”查看已选的题目以及进行排序、删减和加入清单等操作。</p>
    <div class="tips">
      <img src="../../../../assets/tips.png" alt="tips">
    </div>
    <p class="mb-20">3.若确认已选好所需要布置的题目并调整完毕，则可直接在“试题预览”页面中点击“加入清单”，将所选题目组成一份新的套卷去布置。</p>
    <div class="flex">
      <el-checkbox v-model="checked">不再提示</el-checkbox>
      <xybutton
        class="button"
        @click="handleClose"
      >我知道了</xybutton>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'TipsDialog',
  props: ['writtenTipsHidden'],
  data () {
    return {
      dialogVisible: false,
      checked: false
    }
  },
  created () {
    if (!this.writtenTipsHidden) this.dialogVisible = true
  },
  methods: {
    handleClose () {
      if (this.checked) {
        localStorage.setItem('writtenTipsHidden', 1)
      }
      this.dialogVisible = false
    }
  }
}
</script>

<style scoped lang="scss">
  p {
    font-size: 16px;
    color: #333;
  }
  .tips {
    width: 100%;
    height: 57px;
    margin: 4px 0 20px;
    img {
      width: auto;
      height: 57px;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .button {
    background: linear-gradient(to right, #6DBBFC, #309AF2);
    width: 160px !important;
    height: 40px !important;
    color: #fff !important;
    &:hover {
      background: #309AF2;
      color: #fff;
    }
  }
</style>
